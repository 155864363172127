<template>
    <component
      :is="element"
      :class="inputClass"
      :name="name"
      :type="type"
      :value.prop="text"
      @input="update"
      :placeholder="placeholder"
      v-bind="$attrs"
      :required="required"
    />
</template>

<script>
export default {
  model: {
    prop: "text",
    event: "update"
  },

  props: {
    name: {
      type: String
    },
    type: {
      type: String,
      default: "text"
    },
    text: {
      required: true
    },
    placeholder: {
      type: String
    },
    invalid: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputClass() {
      return {
        invalid: this.invalid
      };
    },

    element() {
      return this.type === "textarea" ? this.type : "input";
    }
  },

  methods: {
    update(event) {
      this.$emit("update", event.currentTarget.value);
    }
  }
};
</script>

<style scoped>

.invalid {
  border-color: red !important;
}

</style>
